import {routeNavigate} from "@/router";

export const routes = [
    {
        name: 'teaching',
        path: "/teaching",
        component: () => import('@/views/api/teaching/index.vue'),
        // redirect: '/teaching/course',
        redirect: '/teaching/attendance',
        meta:{title:'教学'},
        props: true,
        children: [
        //     { name: 'teachingHome',meta:{title:'首页',hidden:true},path: 'home',redirect: '/teaching/course'},
        //     { name: 'teachingCourse',meta:{title:'课程'},path: 'course',component: () => import('@/views/api/teaching/course/list.vue')},
           
            // { name: 'teachingQuesList',meta:{title:'题单',props:{priexRoute:'questList'}},path: 'questList',redirect: '/teaching/questList/list'
            //     ,component: {template:"<router-view></router-view>"}
            //     ,children:[
            //         { name: 'teachingQuestListList',meta:{title:'题单列表'},path: 'list',component: () => import('@/views/api/teaching/questList/list.vue') },
            //         { name: 'teachingQuestListInfo',meta:{title:'题单详情'},path: ':list_id(\\d+)/info',component: () => import('@/views/api/teaching/questList/detail.vue') },
            //         { name: 'teachingQuestListCensus',meta:{title:'题单统计详情'},path: 'census/:list_id(\\d+)?',component: () => import('@/views/api/teaching/questList/census.vue') },
            //         { name: 'teachingQuestListQuestion',meta:{title:'题单题目管理'},path: ':list_id(\\d+)/question',component: () => import('@/views/api/teaching/questList/chooseList.vue') },
            //     ]
            // },
        //     { name: 'teachingQuestion',meta:{title:'题目'},path: 'question',redirect: '/teaching/question/list'
        //     ,component: {template:"<router-view></router-view>"}
        //     ,children:[
        //         { name: 'teachingQuestionList',meta:{title:'题目列表'},path: 'list',component: () => import('@/views/api/teaching/question/list.vue') },
        //         { name: 'teachingQuestionInfo',meta:{title:'题目详情'},path: ':question_id(N\\d+|P\\d+|\\d+)/info/:tab(desc|analysis)?',component: () => import('@/views/api/teaching/question/detail.vue') ,children: [
        //                 { name: 'teachingQuestionInfoComment',meta:{title:'题目讨论'},path: 'comment',component: {
        //                         props:['question_id'],
        //                         template:'<comment type="question" :where="{id:question_id||$attrs.question_id}"></comment>',
        //                         components: {
        //                             comment: () => import('@/views/api/com/comment.vue'),
        //                         }
        //                     }
        //                 },
        //                 { name: 'teachingQuestionInfoRecord',meta:{title:'提交记录'},path: 'record',component: () => import('@/views/api/teaching/question/c/record.vue') },
        //             ]
        //         },
        //         { name: 'teachingQuestionCensus',meta:{title:'题目统计详情'},path: 'census/:question_id(N\\d+|P\\d+|\\d+)?',component: () => import('@/views/api/teaching/question/census.vue') },
        //         { name: 'teachingQuestionUseCases',meta:{title:'测试用例'},path: ':question_id(N\\d+|P\\d+|\\d+)/use_cases',component: () => import('@/views/api/teaching/question/use_cases.vue') },
        //     ]
        //     },
        //     { name: 'teachingTask',meta:{title:'作业'},props:{priexRoute:'course'},path: 'task',redirect: '/teaching/task/list'
        //     ,component: {template:"<router-view></router-view>"}
        //     ,children:[
        //         { name: 'teachingTaskList',meta:{title:'作业列表'},path: 'list',component: () => import('@/views/api/teaching/questOther/taskList.vue') },
        //         { name: 'teachingTaskInfo',meta:{title:'作业详情'},path: ':other_id(\\d+)/info',component: () => import('@/views/api/teaching/questOther/detail.vue') },
        //         { name: 'teachingTaskCensus',meta:{title:'作业统计详情'},path: 'census/:other_id(\\d+)?',component: () => import('@/views/api/teaching/questOther/census.vue') },
        //         { name: 'teachingTaskQuestion',meta:{title:'作业题目管理'},path: ':other_id(\\d+)/question',component: () => import('@/views/api/teaching/questOther/chooseList.vue') },
        //     ]
        // },
        //     { name: 'teachingExam',meta:{title:'考试',props:{priexRoute:'exam'}},path: 'exam',redirect: '/teaching/exam/list'
        //         ,component: {template:"<router-view></router-view>"}
        //         ,children:[
        //             { name: 'teachingExamList',meta:{title:'考试列表'},path: 'list',component: () => import('@/views/api/teaching/questOther/examList.vue') },
        //             { name: 'teachingExamInfo',meta:{title:'考试详情'},path: ':other_id(\\d+)/info',component: () => import('@/views/api/teaching/questOther/detail.vue') },
        //             { name: 'teachingExamCensus',meta:{title:'考试统计详情'},path: 'census/:other_id(\\d+)?',component: () => import('@/views/api/teaching/questOther/census.vue') },
        //             { name: 'teachingExamQuestion',meta:{title:'考试题目管理'},path: ':other_id(\\d+)/question',component: () => import('@/views/api/teaching/questOther/chooseList.vue') },
        //         ]
        //     },
        //     { name: 'teachingMatch',meta:{title:'比赛',props:{priexRoute:'match'}},path: 'match',redirect: '/teaching/match/list'
        //         ,component: {template:"<router-view></router-view>"}
        //         ,children:[
        //             { name: 'teachingMatchList',meta:{title:'比赛列表'},path: 'list',component: () => import('@/views/api/teaching/questOther/matchList.vue') },
        //             { name: 'teachingMatchInfo',meta:{title:'比赛详情'},path: ':other_id(\\d+)/info',component: () => import('@/views/api/teaching/questOther/detail.vue') },
        //             { name: 'teachingMatchCensus',meta:{title:'比赛统计详情'},path: 'census/:other_id(\\d+)?',component: () => import('@/views/api/teaching/questOther/census.vue') },
        //             { name: 'teachingMatchQuestion',meta:{title:'比赛题目管理'},path: ':other_id(\\d+)/question',component: () => import('@/views/api/teaching/questOther/chooseList.vue') },
        //         ]
        //     },
            { name: 'teachingAttendance',meta:{title:'出勤'},props:{priexRoute:'attendance'},path: 'attendance',redirect: '/teaching/attendance/list'
                ,component: {template:"<router-view></router-view>"}
                ,children:[
                    { name: 'teachingAttendanceList',meta:{title:'出勤列表'},path: 'list',component: () => import('@/views/api/teaching/questOther/attendanceList.vue') },
                    // { name: 'teachingTaskCensus',meta:{title:'作业统计详情'},path: 'census/:other_id(\\d+)?',component: () => import('@/views/api/teaching/questOther/census.vue') },
                    // { name: 'teachingTaskQuestion',meta:{title:'作业题目管理'},path: ':other_id(\\d+)/question',component: () => import('@/views/api/teaching/questOther/chooseList.vue') },
                ]
            },
            { name: 'teachingTotal',meta:{title:'统计'},props:{priexRoute:'total'},path: 'total',redirect: '/teaching/total/list'
                ,component: {template:"<router-view></router-view>"}
                ,children:[
                    { name: 'teachingTotalList',meta:{title:'统计'},path: 'list',component: () => import('@/views/api/teaching/questOther/stuList.vue') },
                    { name: 'teachingTotalInfo',meta:{title:'班课详情'},path: '/teaching/totalDetail',component: () => import('@/views/api/teaching/questOther/totalDetail.vue') },
                    // { name: 'teachingTaskCensus',meta:{title:'作业统计详情'},path: 'census/:other_id(\\d+)?',component: () => import('@/views/api/teaching/questOther/census.vue') },
                    // { name: 'teachingTaskQuestion',meta:{title:'作业题目管理'},path: ':other_id(\\d+)/question',component: () => import('@/views/api/teaching/questOther/chooseList.vue') },
                ]
            },
            { name: 'teachingTotalTemplate',meta:{title:'统计模板定义'},props:true,path:"/teaching/questOther/totalTemplate", component: () => import('@/views/api/teaching/questOther/totalTemplate.vue')  },
            { name: 'teachingmQuestListInfo',meta:{title:'题单详情',hidden:true},props:true,path: 'quesList/:list_id(\\d+)', component: () => import('@/views/api/teaching/questList/detail.vue')  },
        ],
    },
    { name: 'teachingCourseCreate',meta:{title:'创建课程模板',login:true},path: '/teaching/course/',redirect: '/teaching/course/create'
        , component: () => import('@/views/api/teaching/course/headerSave.vue')
        , children: [
            { name: 'teachingCourseBase',meta:{title:'创建课程'},path: 'create',component: () => import('@/views/api/teaching/course/teachingCourseBase.vue') },
        ],
    },
    { name: 'teachingCourseDetail',meta:{title:'课程详情',login:true},path: '/teaching/course/:course_id(\\d+)/detail',props:true, component: () => import('@/views/api/course/detail.vue')
        , children: [
            { name: 'teachingCourseDetailMenu',meta:{title:'课程目录'},path: 'study/:list_id(\\d+)?',component: () => import('@/views/api/teach_plan/course/c/menu.vue')},
            { name: 'teachingCourseDetailComment',meta:{title:'学员评价'},path: 'comment',component: {
                    props:['course_id'],
                    template:'<comment type="course" :where="{id:course_id}"></comment>',
                    components:{
                        comment:()=>{return import('@/views/api/com/comment.vue')},
                    }
                }
            },
        ]
    },
    { name: 'teachingCourseDetailOrderSure',meta:{title:'开通课程',login:true},path: '/teaching/course/:course_id(\\d+)/detail/order/:order_id(\\d+)',props:true,component: {
            template:'<div><breadcrumb></breadcrumb><order-sure :order_id="order_id"></order-sure></div>',
            props:['order_id'],
            components:{
                orderSure :() => import('@/views/api/com/orderSure.vue')
            }
        }
    },
    { name: 'teachingCourseEdit',meta:{title:'编辑课程模板',login:true},props:true,path: '/teaching/course/:course_id(\\d+)',redirect: '/teaching/course/:course_id(\\d+)/edit'
        , component: {
            props:['course_id'],
            template:'<headerSave ref="headerSave" type="course" :where="{id:course_id}"></headerSave>',
            components:{
                headerSave:()=>{return import('@/views/api/teaching/course/headerSave.vue')},
            },
            methods:{
                // routeUpdate:function(){
                //     if(this.course_id > 0){
                //         this.$http.post('/xapi/course.course/detail' ,{id:this.course_id}).then(({data})=>{
                //             if((data.powers || {}).plan_create){
                //                 this.$refs.headerSave.rightButtonLists = [{name:'CourseGeneratePlan',callback(){
                //                         routeNavigate({courseId:data.id},'/teachPlan/course/create');
                //                     },title:'生成教案'},]
                //             }
                //         });
                //     }
                // },
            },
        }
        , children: [
            { name: 'teachingCourseBaseEdit',meta:{title:'编辑课程'},path: 'edit',component: () => import('@/views/api/teaching/course/teachingCourseBase.vue') },
            { name: 'teachingCourseStudy',meta:{title:'学生管理'},path: 'study',component: () => import('@/views/api/teaching/course/teachingCourseStudy.vue')},
            { name: 'teachingCourseTeach',meta:{title:'教师管理'},path: 'teach',component: () => import('@/views/api/teaching/course/teachingCourseTeach.vue') },
            { name: 'teachingCourseCont',meta:{title:'课程内容'},path: 'cont',component: () => import('@/views/api/teaching/course/courseCont.vue') },
            { name: 'teachingCourseListCreate',meta:{title:'添加条目'},path: 'list',component: () => import('@/views/api/teaching/course/listSave.vue') },
            { name: 'teachingCourseListEdit',meta:{title:'编辑条目'},path: 'list/:list_id(\\d+)',props:true,component: () => import('@/views/api/teaching/course/listSave.vue') },
            { name: 'teachingCourseTask',meta:{title:'作业列表'},path: 'task', component: () => import('@/views/api/teaching/course/taskList.vue')  },
            { name: 'teachingCourseTaskCreate',meta:{title:'添加作业'},path: 'task/save', component: () => import('@/views/api/teaching/questOther/taskSave.vue')  },
            { name: 'teachingCourseTaskInfo',meta:{title:'做作业'},path: 'task/:other_id(\\d+)', component: () => import('@/views/api/teaching/questOther/detail.vue')  },
            { name: 'teachingCourseTaskEdit',meta:{title:'编辑作业'},path: 'task/:other_id(\\d+)/save', component: () => import('@/views/api/teaching/questOther/taskSave.vue')  },
            { name: 'teachingCourseTaskCensus',meta:{title:'作业统计详情'},path: 'task/:other_id(\\d+)/census',component: () => import('@/views/api/teaching/questOther/census.vue') },
            { name: 'teachingCourseTaskQuestion',meta:{title:'课程作业题目管理'},path: 'task/:other_id(\\d+)/question', component: () => import('@/views/api/teaching/questOther/chooseList.vue')  },
        ],
    },
    // { name: 'teachingCourseStudySpeed',path: "/teaching/course/:course_id(\\d+)/study/:study_user_id(\\d+)?/speed",meta:{title:'学习进度',login:true},component: () => import('@/views/me/speed.vue'),},
    { name: 'teachingCourseStudySpeed',path: "/teaching/course/study/:study_user_id(\\d+)?/speed",meta:{title:'学习进度',login:true},component: () => import('@/views/me/speed.vue'),},
    { name: 'teachingCourseStudyComment', path: "/teaching/course/:course_id(\\d+)/study/:study_user_id(\\d+)?/comment", meta:{title:'结果评论',login:true}, component: () => import('@/views/me/comment.vue'),
    },
    // { name: 'teachingQuestionCreate',meta:{title:'添加题目',login:true},path: '/teaching/question/save', component: {
    //         template:`<question-save  style="margin-top: 20px;" title="添加题目"></question-save>`,
    //         components:{
    //             questionSave:()=> import('@/views/api/teaching/question/save.vue')
    //         }
    //     }
    // },
    // { name: 'teachingQuestionEdit',meta:{title:'编辑题目',login:true},path: '/teaching/question/:question_id(N\\d+|P\\d+|\\d+)/save', component: {
    //         props:['question_id'],
    //         template:`<question-save  style="margin-top: 20px;" :question_id="question_id" title="编辑题目"></question-save>`,
    //         components:{
    //             questionSave:()=> import('@/views/api/teaching/question/save.vue')
    //         }
    //     }
    // },
    // { name: 'teachingQuestListCreate',meta:{title:'添加题单',login:true},path: '/teaching/questList/save', component: () => import('@/views/api/teaching/questList/save.vue')  },
    // { name: 'teachingQuestListEdit',meta:{title:'编辑题单',login:true},path: '/teaching/questList/:list_id(\\d+)/save', component: () => import('@/views/api/teaching/questList/save.vue')  },
    // { name: 'teachingExamCreate',meta:{title:'添加考试',login:true},path: '/teaching/exam/save'
    //     , component: {
    //         template:`<div style="margin-top: 20px;"><exam-save></exam-save></div>`,
    //         components:{
    //             examSave:() => import('@/views/api/teaching/questOther/examSave.vue')
    //         }
    //     }
    // },
    // { name: 'teachingExamEdit',meta:{title:'编辑考试',login:true},path: '/teaching/exam/:other_id(\\d+)/save'
    //     , component: {
    //         props:['other_id'],
    //         template:`<div style="margin-top: 20px;"><exam-save :other_id="other_id"></exam-save></div>`,
    //         components:{
    //             examSave:() => import('@/views/api/teaching/questOther/examSave.vue')
    //         }
    //     }
    // },
    // { name: 'teachingMatchCreate',meta:{title:'添加比赛',login:true},path: '/teaching/match/save'
    //     , component: {
    //         template:`<div style="margin-top: 20px;"><match-save></match-save></div>`,
    //         components:{
    //             matchSave:() => import('@/views/api/teaching/questOther/matchSave.vue')
    //         }
    //     }
    // },
    // { name: 'teachingMatchEdit',meta:{title:'编辑比赛',login:true},path: '/teaching/match/:other_id(\\d+)/save'
    //     , component: {
    //         props:['other_id'],
    //         template:`<div style="margin-top: 20px;"><match-save :other_id="other_id"></match-save></div>`,
    //         components:{
    //             matchSave:() => import('@/views/api/teaching/questOther/matchSave.vue')
    //         }
    //     }
    // },
    // { name: 'teachingTaskCreate',meta:{title:'添加作业',login:true},props:{priexRoute:'course'},path: '/teaching/task/save'
    //     , component: {
    //         template:`<div style="margin-top: 20px;"><task-save></task-save></div>`,
    //         components:{
    //             taskSave:() => import('@/views/api/teaching/questOther/taskSave.vue')
    //         }
    //     }
    // },
    // { name: 'teachingTaskEdit',meta:{title:'编辑作业',login:true},props:{priexRoute:'course'},path: '/teaching/task/:other_id(\\d+)/save'
    //     , component: {
    //         props:['other_id'],
    //         template:`<div style="margin-top: 20px;"><task-save :other_id="other_id"></task-save></div>`,
    //         components:{
    //             taskSave:() => import('@/views/api/teaching/questOther/taskSave.vue')
    //         }
    //     }
    // },
]